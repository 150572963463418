import { LineItem, Order } from '@commercelayer/sdk';
import { AppCommerceLayerClient } from '@lib/AppCommerceLayerClient';
import {
  getBundleMaxQuantity,
  getRequiredQuantity,
  isBundleAvailable,
} from '@lib/utils/bundleUtils';
import { getMaxDeliveryDays } from '@lib/utils/clUtils';
import { MAX_CART_QTY_FALLBACK } from '@shared/constants';

export const checkIfPossibleAddToCart = (order: Order, maxQtCL: number, skuCode: string) => {
  if (!order) {
    return true;
  }
  const quantityCart = getCartQuantityforProduct(order, skuCode)!;

  return quantityCart < maxQtCL;
};

export const getCartQuantityforProduct = (order: Order, skuCode: string): number | undefined => {
  if (!order || !order.line_items) {
    return 0;
  }

  return (
    order.line_items.find((item) => item.sku_code === skuCode)?.quantity ||
    order.line_items.find((item) => item.bundle_code === skuCode)?.quantity ||
    0
  );
};

export const getOrderInfo = (lineItems: LineItem[]) => {
  return lineItems.reduce(
    (obj, i) => {
      if (i.metadata?.levels) {
        const { minDay, maxDay } = getMaxDeliveryDays(i.metadata?.levels);
        obj.maxDays = Math.max(maxDay, obj.maxDays);
        obj.minDays = Math.max(minDay, obj.minDays);
      }
      return obj;
    },
    { maxDays: 0, minDays: 0 }
  );
};

export const checkAndUpdateAvailabilities = async (
  client: AppCommerceLayerClient,
  lineItems: LineItem[] = []
) => {
  let orderIsChanged = false;
  for await (const lineItem of lineItems.filter(
    (l) => l?.item_type == 'skus' || l?.item_type == 'bundles'
  )) {
    const availability = lineItem.bundle_code
      ? await getBundleAvailability(client, lineItem.bundle_code)
      : await getProductAvailability(client, lineItem.sku_code);
    if (!availability.available) {
      orderIsChanged = true;
      await client.removeLineItem(lineItem);
    } else if ((lineItem.quantity || 0) > availability.maxQuantity) {
      orderIsChanged = true;
      await client.updateItemAvailability(lineItem.id, availability.maxQuantity);
    }
  }
  return orderIsChanged;
};

type ProductOrBundleAvailability = {
  maxQuantity: number;
  available: boolean;
};

const getProductAvailability = async (
  client: AppCommerceLayerClient,
  skuCode: string
): Promise<ProductOrBundleAvailability> => {
  const sku = await client.getProductFromSku(skuCode);
  if (sku) {
    const inventory = sku?.inventory;
    const maxQuantity = Math.min(
      inventory?.quantity,
      sku.metadata?.max_cart_qty || MAX_CART_QTY_FALLBACK
    );
    const available = inventory?.available;

    return { maxQuantity, available };
  }
  return { maxQuantity: 0, available: false };
};

const getBundleAvailability = async (
  client: AppCommerceLayerClient,
  skuCode: string
): Promise<ProductOrBundleAvailability> => {
  const bundle = await client.getBundleFromSku(skuCode);
  if (bundle) {
    const { sku_list, skus, metadata } = bundle;
    const { max_cart_qty } = metadata;
    const requiredQuantity = getRequiredQuantity(sku_list!.sku_list_items);
    const available = isBundleAvailable(skus, requiredQuantity);
    const maxQuantity = Math.min(
      getBundleMaxQuantity(skus, requiredQuantity),
      max_cart_qty || MAX_CART_QTY_FALLBACK
    );

    return { maxQuantity, available };
  }
  return { maxQuantity: 0, available: false };
};
