import { Bundle, Price, Sku } from '@commercelayer/sdk';
import { ResponsiveImageFragment } from '@graphql/generated';
import {
  getBundleMaxQuantity,
  getLevels,
  getRequiredQuantity,
  isBundleAvailable,
} from '@lib/utils/bundleUtils';
import { MAX_CART_QTY_FALLBACK } from '@shared/constants';

export const productToProductData = (
  skuCode: string,
  product: Sku,
  image: ResponsiveImageFragment,
  url: string,
  name: string
) => {
  const inventory = product?.inventory;
  const maxQuantity = Math.min(
    inventory.quantity,
    product.metadata?.max_cart_qty || MAX_CART_QTY_FALLBACK
  );
  const available = inventory.available;
  const option = product.sku_options?.[0] ?? null;
  const metadata = product?.metadata;

  const price = product?.prices?.[0] ?? null;

  //create obj for cart display
  const levels = inventory.levels;
  return {
    id: product.id,
    type: 'skus',
    name,
    image,
    price,
    skuCode,
    maxQuantity,
    available,
    option,
    levels,
    url,
    metadata,
  };
};

export const bundleToBundleData = (
  skuCode: string,
  bundle: Bundle,
  image: ResponsiveImageFragment,
  url: string,
  name: string
) => {
  const id = bundle.id;
  const skus = bundle.skus;

  const { max_cart_qty, excise_duties } = bundle.metadata;

  const price: Price = {
    currency_code: bundle.currency_code,
    amount_cents: bundle.price_amount_cents,
    amount_float: bundle.price_amount_float,
    formatted_amount: bundle.formatted_price_amount,
    id: null,
    type: null,
    created_at: null,
    updated_at: null,
  };

  const requiredQuantity = getRequiredQuantity(bundle.sku_list.sku_list_items);
  const available = isBundleAvailable(skus, requiredQuantity);
  const maxQuantity = Math.min(
    getBundleMaxQuantity(skus, requiredQuantity),
    max_cart_qty || MAX_CART_QTY_FALLBACK
  );

  const levels = getLevels(skus, maxQuantity);
  return {
    id,
    type: 'bundles',
    name,
    image,
    price,
    skuCode,
    maxQuantity,
    available,
    levels,
    url,
    metadata: {
      max_cart_qty,
      excise_duties,
    },
  };
};
