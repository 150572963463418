import { HTMLAttributeAnchorTarget, ReactNode } from 'react';

type Props = {
  externalLink: string;
  id: string;
  className: string;
  children?: ReactNode;
  target: HTMLAttributeAnchorTarget;
  rel: string;
  title?: string;
};

export const ExternalLink = ({
  externalLink,
  id,
  title = null,
  className,
  children,
  target,
  rel,
}: Props) => {
  return (
    <a href={externalLink} className={className} target={target} rel={rel} key={id}>
      {title}
      {children}
    </a>
  );
};
